export const GET_ACCOUNTS_TREE = 'GET_ACCOUNTS_TREE'; 
export const GET_COST_CENTER_TREE = 'GET_COST_CENTER_TREE';
export const GET_JOURNAL_VOUCHERS = 'GET_JOURNAL_VOUCHERS';
export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_CHEQUE_BOOK = 'GET_CHEQUE_BOOK';
export const GET_BANK_VOUCHER = 'GET_BANK_VOUCHER';
export const GET_CASH_VOUCHER = 'GET_CASH_VOUCHER';
export const GET_ACCOUNT_STATMENT_REPORT = 'GET_ACCOUNT_STATMENT_REPORT';
export const GET_ACCOUNT_MOTION_REPORT = 'GET_ACCOUNT_MOTION_REPORT';
export const GET_ACCOUNT_TRIAL_REPORT = 'GET_ACCOUNT_TRIAL_REPORT';
export const GET_REVENUE_EXPENSE = 'GET_REVENUE_EXPENSE';
export const GET_ACCOUNT_REPORTS = 'GET_ACCOUNT_REPORTS';
export const GET_DETAILS_REPORT = 'GET_DETAILS_REPORT';
export const GET_VOUCHERS_REVIEW_REQUESTS = 'GET_VOUCHERS_REVIEW_REQUESTS';


