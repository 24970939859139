import {
    GET_SHARED_GRADE_FEES, GET_STUDENT_PAYMENT_PER_FEES_TYPE_CATEGORY
} from '../constants/FeesData';


const initStateShared = {
    printGradeFeesList: [],
    printGradeFeesListStatus: false,
    printGradeFeesListMsg: '',
    printGradeFeesListTotalItems: 0,
    printGradeFeesListCurrentPage: 0,

    studentPaymentFeesCategoryList: [],
    studentPaymentFeesCategoryListStatus: false,
    studentPaymentFeesCategoryListMsg: '',
    studentPaymentFeesCategoryListTotalItems: 0,
    studentPaymentFeesCategoryListCurrentPage: 0,

}

const test = (state = initStateShared, action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_SHARED_GRADE_FEES:
            return {
                ...state,
                printGradeFeesList: payload?.data?.result || [],
                printGradeFeesListStatus: payload?.status || false,
                printGradeFeesListMsg: payload?.msg || 'Something Went Wrong',
                printGradeFeesListTotalItems: payload?.data?.totalItems || 0,
                printGradeFeesListCurrentPage: payload?.data?.currentPage || 0,
            }

        case GET_STUDENT_PAYMENT_PER_FEES_TYPE_CATEGORY:
            return {
                ...state,

                studentPaymentFeesCategoryList: payload?.data?.result || [],
                studentPaymentFeesCategoryListStatus: payload?.status || false,
                studentPaymentFeesCategoryListMsg: payload?.msg || 'Something Went Wrong',
                studentPaymentFeesCategoryListTotalItems: payload?.data?.totalItems || 0,
                studentPaymentFeesCategoryListCurrentPage: payload?.data?.currentPage || 0,
            }



        default:
            return state;
    }
    return state;
}

export default test;