export const GET_ACCOUNT_AREA = "GET_ACCOUNT_AREA";
export const GET_STORE_CATEGORY = "GET_STORE_CATEGORY";
export const GET_DISTRIBUTERS = "GET_DISTRIBUTERS";
export const GET_PUBLISHERS = "GET_PUBLISHERS";
export const GET_STORE_BOOKS = "GET_STORE_BOOKS";
export const GET_BOOKS_DELIVERE_TO_TEACHER = "GET_BOOKS_DELIVERE_TO_TEACHER";
export const GET_BOOK_WITH_AVAILABLE_COUNT = "GET_BOOK_WITH_AVAILABLE_COUNT";
export const GET_BOOKS_DELIVERED_TO_STUDENT = "GET_BOOKS_DELIVERED_TO_STUDENT";
export const GET_PURCHASED_BOOKS = "GET_PURCHASED_BOOKS";
export const GET_SELLED_BOOKS = "GET_SELLED_BOOKS";
export const GET_BOOKS_RECEIVED_FROM_STUDENT = "GET_BOOKS_RECEIVED_FROM_STUDENT";
export const GET_BOOKS_RECEIVED_FROM_TEACHER = "GET_BOOKS_RECEIVED_FROM_TEACHER";
export const GET_STORE_PURCHASE_BOOKS_REPORTS = "GET_STORE_PURCHASE_BOOKS_REPORTS";
export const GET_STORE_SOLID_BOOKS = "GET_STORE_SOLID_BOOKS";
export const GET_STORE_RECEIVED_BOOKS = "GET_STORE_RECEIVED_BOOKS";
export const GET_STORE_CLASSIFICATION = "GET_STORE_CLASSIFICATION";
export const GET_STUDENT_LOGS = "GET_STUDENT_LOGS";

