import React, { Component } from 'react';
import { Button, Result } from 'antd';
import { connect } from 'react-redux'; // Import connect from 'react-redux'
import AppLocale from "lang";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.currentAppLocale = this.currentAppLocale.bind(this);
  }

  currentAppLocale(title) {
    const locale = this.props.locale; // Access locale from props
    return AppLocale[locale]?.messages[title];
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Result
            status="500"
            title="500"
            subTitle={this.currentAppLocale('Internal_Server_Error')}
            extra={<Button href='/' type="primary">{this.currentAppLocale('Home')}</Button>}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = state => ({
  locale: state.theme.locale,
});

export default connect(mapStateToProps)(ErrorBoundary); // Connect the component to Redux
