export const GET_STUDENT_REPORT_COLUMN = 'GET_STUDENT_REPORT_COLUMN';
export const GET_STUDENT_DYNAMIC_REPORT_LIST = 'GET_STUDENT_DYNAMIC_REPORT_LIST';
export const DELETE_STUDENT_DYNAMIC_REPORT = 'DELETE_STUDENT_DYNAMIC_REPORT';
export const UPDATE_STUDENT_DYNAMIC_REPORT = 'UPDATE_STUDENT_DYNAMIC_REPORT';
export const GET_PRINT_REPORT_STUDENT_LIST = 'GET_PRINT_REPORT_STUDENT_LIST';
export const GET_PRINT_STUDENT_LIST = 'GET_PRINT_STUDENT_LIST'


export const ADD_EMPLOYEE_DYNAMIC_REPORT = 'ADD_EMPLOYEE_DYNAMIC_REPORT';
export const GET_EMPLOYEE_DYNAMIC_REPORT_LIST = 'GET_EMPLOYEE_DYNAMIC_REPORT_LIST';
export const GET_EMPLOYEE_DYNAMIC_REPORT_BY_ID = 'GET_EMPLOYEE_DYNAMIC_REPORT_BY_ID';
export const UPDATE_EMPLOYEE_DYNAMIC_REPORT = 'UPDATE_EMPLOYEE_DYNAMIC_REPORT';
export const DELETE_EMPLOYEE_DYNAMIC_REPORT = 'DELETE_EMPLOYEE_DYNAMIC_REPORT';
export const RESET_EMPLOYEE_DYNAMIC_REPORT = 'RESET_EMPLOYEE_DYNAMIC_REPORT';
export const GET_EMPLOYEE_REPORT_COLUMN = 'GET_EMPLOYEE_REPORT_COLUMN';
export const GET_PRINT_REPORT_EMPLOYEE_LIST = 'GET_PRINT_REPORT_EMPLOYEE_LIST'

export const GET_REPORT_LIST = 'GET_REPORT_LIST';

export const GET_PRINT_FEES_STU_REPORT_LIST = 'GET_PRINT_FEES_STU_REPORT_LIST';
export const GET_PRINT_FEES_STUDENT_LIST = 'GET_PRINT_FEES_STUDENT_LIST';



