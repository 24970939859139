import { Select, Menu } from 'antd';
import { useSelector } from 'react-redux';
// import unwind from 'javascript-unwind';
import { LP_API_BASE_URL } from "constants/ApiConstant";
import parser from 'html-react-parser';
import moment from 'moment';
const { Option } = Select;

export const numUpto3Digit = (num) => {
    return `${parseFloat(num).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} KD`
}
export const numberArabicWords = ['صفر', ' وينقل للصف الأول', ' وينقل للصف الثاني', ' وينقل للصف الثالث', ' وينقل للصف الرابع', ' وينقل للصف الخامس', ' وينقل للصف السادس', ' وينقل للصف السابع', ' وينقل للصف الثامن', ' وينقل للصف التاسع', ' وينقل للصف العاشر', ' وينقل للصف الحادي عشر', ' وينقل للصف الثاني عشر', ''];

export const totalPendingAmount = (totalAmount, totalDiscount, totalPaid) => {
    totalAmount = totalAmount ? parseFloat(totalAmount) : 0;
    totalDiscount = totalDiscount ? parseFloat(totalDiscount) : 0;
    totalPaid = totalPaid ? parseFloat(totalPaid) : 0;
    if (totalAmount > 0 && totalDiscount >= 0 && totalPaid >= 0 && totalPaid <= totalAmount) {
        let total = parseFloat(totalAmount - totalDiscount - totalPaid).toFixed(3)
        if (total > 0) {
            return `${total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} KD`
        } else {
            return '0 KD'
        }
    } else {
        return `0 KD`
    }
}


export const NumToWord = (inputNumber) => {
    var str = new String(inputNumber)
    var splt = str.split("");
    var rev = splt.reverse();
    var once = ['Zero', ' One', ' Two', ' Three', ' Four', ' Five', ' Six', ' Seven', ' Eight', ' Nine'];
    var twos = ['Ten', ' Eleven', ' Twelve', ' Thirteen', ' Fourteen', ' Fifteen', ' Sixteen', ' Seventeen', ' Eighteen', ' Nineteen'];
    var tens = ['', 'Ten', ' Twenty', ' Thirty', ' Forty', ' Fifty', ' Sixty', ' Seventy', ' Eighty', ' Ninety'];

    var numLength = rev.length;
    var word = new Array();
    var i = 0;
    var j = 0;

    for (i = 0; i < numLength; i++) {
        switch (i) {
            case 0:
                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                    word[j] = '';
                }
                else {
                    word[j] = '' + once[rev[i]];
                }
                word[j] = word[j];
                break;
            case 1:
                aboveTens();
                break;
            case 2:
                if (rev[i] == 0) {
                    word[j] = '';
                }
                else if ((rev[i - 1] == 0) || (rev[i - 2] == 0)) {
                    word[j] = once[rev[i]] + " Hundred ";
                }
                else {
                    word[j] = once[rev[i]] + " Hundred and";
                }
                break;
            case 3:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = '';
                }
                else {
                    word[j] = once[rev[i]];
                }
                if ((rev[i + 1] != 0) || (rev[i] > 0)) {
                    word[j] = word[j] + " Thousand";
                }
                break;
            case 4:
                aboveTens();
                break;
            case 5:
                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                    word[j] = '';
                }
                else {
                    word[j] = once[rev[i]];
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + " Lakh";
                }
                break;

            case 6:
                aboveTens();
                break;

            case 7:
                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                    word[j] = '';
                }
                else {
                    word[j] = once[rev[i]];
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + " Crore";
                }
                break;

            case 8:
                aboveTens();
                break;
            default: break;
        }
        j++;
    }

    function aboveTens() {
        if (rev[i] == 0) { word[j] = ''; }
        else if (rev[i] == 1) { word[j] = twos[rev[i - 1]]; }
        else { word[j] = tens[rev[i]]; }
    }

    word.reverse();
    var finalOutput = '';
    for (i = 0; i < numLength; i++) {
        finalOutput = finalOutput + word[i];
    }
    return finalOutput;
}

export const PrintDate = formatDate(new Date())
export const PrintDateArabic = formatDateArabic(new Date())
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
export function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('-');
}
function formatDateArabic(date) {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-');
}

export const employeeExperinceYears = [];
for (let i = 0; i < 45; i++) {
    employeeExperinceYears.push({ id: i, name: `${i} سنة` });
}

export const genderList = [{ id: 0, name: 'Female', ar_name: ' انثى' }, { id: 1, name: 'Male', ar_name: ' ذكر' }];
export const statusList = [{ id: 0, ar_name: "غير فعال", name: 'Deactive' }, { id: 1, name: 'Active', ar_name: "مستمر" }, { id: 2, name: 'New', ar_name: "مستجد" }, { id: 3, name: 'Failed', ar_name: "باقي للاعادة" }, { id: 4, ar_name: "انسحاب / انتقال", name: 'Left' }];
export const socialList = [
    { id: 0, name: 'Late/Dead', ar_name: 'متوفي ' },
    { id: 1, name: 'Divorced', ar_name: 'مطلق' },
    { id: 2, name: 'Married', ar_name: 'متزوج ' },
    { id: 3, name: 'Widower', ar_name: 'ارمل ' }
];
export const yesNoList = [{ id: 1, ar_name: "نعم", name: 'Yes' }, { id: 0, ar_name: "لا", name: 'No' }];
export const lockUnlockList = [{ id: 1, ar_name: "مفتوح", name: 'Open' }, { id: 0, ar_name: "مغلق", name: 'Closed' }];
export const motherFatherList = [{ id: 1, ar_name: ' الأب', name: 'Father' }, { id: 2, ar_name: 'الأم', name: 'Mother' }];
export const studentTypeList = [{ id: 1, name: 'Student - Employee', ar_name: 'طالب موظف' }, { id: 2, name: 'Student - Government', ar_name: 'طالب حكومي' }, { id: 3, name: 'Normal Student', ar_name: 'طالب عادي' }];
export const studentRejectReasonList = [{ id: 1, name: 'Incomplete Documents', ar_name: 'Incomplete Documents' }, { id: 2, name: 'Behavior Problems', ar_name: 'Behavior problems' }];
export const feesPayeeList = [{ id: 1, ar_name: ' الأب', name: 'Father' }, { id: 2, ar_name: 'الأم', name: 'Mother' }, { id: 3, ar_name: 'شخص اخر', name: 'Other' }];
export const paymentMethod = [{ id: 1, ar_name: 'شيك', name: 'Cheque' }, { id: 2, ar_name: 'تحويل بنكي', name: 'Bank Transfer' }, { id: 3, ar_name: 'KNET', name: 'KNET' }, { id: 4, ar_name: 'كاش', name: 'Cash' }, { id: 5, ar_name: 'اونلاين', name: 'Online' }]
export const returnMethod = [{ id: 1, ar_name: 'شيك', name: 'Cheque' }, { id: 2, ar_name: 'تحويل بنكي', name: 'Bank Transfer' }, { id: 3, ar_name: 'كاش', name: 'Cash' }]
export const acceptRegistrationList = [{ id: 1, ar_name: "نعم", name: 'Yes' }, { id: 2, ar_name: "لا", name: 'No' }, { id: 3, ar_name: "Decline Re-registration with reason", name: 'Decline Re-registration with reason' }]
export const percentageTypeList = [{ id: 1, ar_name: 'Percentage', name: 'Percentage' }, { id: 2, ar_name: 'Brother Percentage', name: 'Brother Percentage' }]
export const paidAmountList = [{ id: 1, ar_name: 'More Than', name: 'More Than' }, { id: 2, ar_name: 'Less Than', name: 'Less Than' }, { id: 3, ar_name: 'Equal', name: 'Equal' }]
export const ACADEMIC = 1;
export const MANAGMENT = 2;
export const OPERATOR = 3;
export const employeeDepartmentsList = [
    { id: 1, section_id: MANAGMENT, name: 'Certificate Department', ar_name: 'قسم الشهادات' },
    { id: 2, section_id: MANAGMENT, name: 'Financial Department', ar_name: 'قسم الحسابات' },
    { id: 3, section_id: MANAGMENT, name: 'Registration Department', ar_name: 'قسم التسجيل ' },
    { id: 4, section_id: MANAGMENT, name: 'Libraries Department', ar_name: 'قسم المكتبات ' },
    { id: 5, section_id: MANAGMENT, name: 'Stock Department', ar_name: 'قسم المخازن ' },
    { id: 6, section_id: MANAGMENT, name: 'Media Section', ar_name: 'قسم الاعلام ' },
    { id: 7, section_id: MANAGMENT, name: 'Employees Affairs Department', ar_name: 'قسم شئون الموظفين ' },
    { id: 8, section_id: MANAGMENT, name: 'Secretarial Department', ar_name: 'قسم السكرتاريه' },
    { id: 9, section_id: MANAGMENT, name: 'IT Department', ar_name: 'قسم نظم المعلومات' },
    { id: 10, section_id: MANAGMENT, name: 'Administration Department', ar_name: 'قسم الادارة' },
    { id: 11, section_id: MANAGMENT, name: 'Social Work Department', ar_name: 'قسم الاخصائي الاجتماعي' },
    { id: 12, section_id: OPERATOR, name: 'Maids', ar_name: 'عاملات' },
    { id: 13, section_id: OPERATOR, name: 'Guards', ar_name: 'حراس' },
    { id: 14, section_id: MANAGMENT, name: 'Supervisor', ar_name: 'قسم الاشراف' },
    { id: 15, section_id: MANAGMENT, name: 'Medical Department', ar_name: 'القسم الطبي' },

];
export const employeeSectionsList = [
    { id: ACADEMIC, name: 'Academic', ar_name: 'اكاديمي' },
    { id: MANAGMENT, name: 'Administration', ar_name: 'إداري ' },
    { id: OPERATOR, name: 'Maids/Guards', ar_name: 'عاملات / حراس ' },
];
export const genderListOptions = (lang) => genderList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const statusListOptions = (lang) => statusList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const yesNoListOptions = (lang) => yesNoList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const socialListOptions = (lang) => socialList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const motherFatherOptions = (lang) => motherFatherList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const studentTypeListOptions = (lang) => studentTypeList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const studentRejectReasonListOptions = (lang) => studentRejectReasonList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const feesPayeeListOptions = (lang) => feesPayeeList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const paymentMethodListOptions = (lang) => paymentMethod.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const acceptRegistrationListOptions = (lang) => acceptRegistrationList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const percentageTypeListOptions = (lang) => percentageTypeList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const paidAmountListOptions = (lang) => paidAmountList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const employeeDepartmentsListOptions = (lang, sectionId) => employeeDepartmentsList.filter(el => el.section_id === sectionId).map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });
export const employeeSectionsListOptions = (lang) => employeeSectionsList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Option> });


export const paymentMethodMenuItems = (lang) => paymentMethod.map((el, index) => { return el = <Menu.Item key={el.id}>{lang === 'ar' ? el.ar_name : el.name}</Menu.Item> });
export const SchoolYearListOptions = () => {
    const schoolYear = useSelector(state => state.shared.schoolYear);
    return schoolYear && schoolYear.map((el, index) => { return el = <Option key={index} value={el.id}>{el.year}</Option> })
};





export const empSocialStatusList = [{ id: 1, name: 'Unmarried', ar_name: 'اعزب' }, { id: 2, name: 'Married', ar_name: 'متزوج' }, { id: 3, name: 'Married and Dependent', ar_name: 'متزوج ويعول' }, { id: 4, name: 'Widower', ar_name: 'ارمل' }];

export const Emp_countryListOptions = (lang) => {
    const employeeCountryList = useSelector(state => state.employeeArea.employeeCountryList);
    const locale = useSelector(state => state.theme.locale)
    return employeeCountryList && employeeCountryList.map((el, index) => { return el = <Option key={index} value={el.id}>{locale == 'ar' ? el.ar_name : el.name}</Option> }
    )
};
export const Birth_Passport_GraduationCountry_Options = (lang) => {
    const employeeB_P_G_CountryList = useSelector(state => state.employeeArea.employeeB_P_G_CountryList);
    const locale = useSelector(state => state.theme.locale)
    return employeeB_P_G_CountryList && employeeB_P_G_CountryList.map((el, index) => { return el = <Option key={index} value={el.id}>{locale == 'ar' ? el.ar_name : el.name}</Option> })
};
export const empSocialStatusOptions = (lang) => empSocialStatusList.map((el, index) => { return el = <Option key={index} value={el.id}>{lang == 'ar' ? el.ar_name : el.name}</Option> });
export const experinceYearsOptions = (lang) => employeeExperinceYears && employeeExperinceYears.map((el, index) => { return el = <Option key={index} value={el.id}>{el.name}</Option> });








export const tafqeet = (numIn = 0, code, op = {}) => {
    let iso = tafqeetISOList[code]; if (!iso) throw new Error("Currency code not in the list!");
    let arr = (numIn += "").split((0.1).toLocaleString().substring(1, 2)),
        out = nToW(arr[0], iso.uGender == "female", op, [iso.uSingle, iso.uDouble, iso.uPlural]),
        frc = arr[1] ? (arr[1] + "000").substring(0, iso.fraction) : 0;
    if (frc != 0) {
        out += "، و" + (op.format == "short" ? frc + "/1" + "0".repeat(+iso.fraction) + " " + iso.uSingle :
            nToW(frc, iso.sGender == "female", op, [iso.sSingle, iso.sDouble, iso.sPlural]));
    }
    return out;

    function nToW(numIn = 0, fm, { comma, legal } = {}, names) {
        if (numIn == 0) return "صفر " + iso.uSingle;
        let tS = [, "ألف", "مليون", "مليار", "ترليون", "كوادرليون", "كوينتليون", "سكستليون"], tM = ["", "واحد", "اثنان", "ثلاثة", "أربعة", "خمسة", "ستة", "سبعة", "ثمانية", "تسعة", "عشرة"], tF = ["", "واحدة", "اثنتان", "ثلاث", "أربع", "خمس", "ست", "سبع", "ثمان", "تسع", "عشر"],
            num = (numIn += ""), tU = [...tM], t11 = [...tM], out = "", n99, SpWa = " و", miah = "مائة",
            last = ~~(((numIn = "0".repeat(numIn.length * 2 % 3) + numIn).replace(/0+$/g, "").length + 2) / 3) - 1;
        t11[0] = "عشر"; t11[1] = "أحد"; t11[2] = "اثنا";
        numIn.match(/.{3}/g).forEach((n, i) => +n && (out += do999(numIn.length / 3 - i - 1, n, i == last), i !== last && (out += (comma == 'on' ? "،" : "") + SpWa)));
        let sub = " " + names[0], n = +(num + "").slice(-2); if (n > 10) sub = " " + tanween(names[0]); else if (n > 2) sub = " " + names[2];
        else if (n > 0) sub = names[n - 1] + " " + (fm ? tF[n] : tM[n]);
        return out + sub;

        function tanween(n, a = n.split` `, L = a.length - 1) {
            const strTF = (str, l = str.slice(-1), o = str + "ًا") => { return "ا" == l ? o = str.slice(0, -1) + "ًا" : "ة" == l && (o = str + "ً"), o; };
            for (let i = 0; i <= L; i++)if (!i || i == L) a[i] = strTF(a[i]); return a.join` `;
        }

        function do999(sPos, num, last) {
            let scale = tS[sPos], n100 = ~~(num / 100), nU = (n99 = num % 100) % 10, n10 = ~~(n99 / 10), w100 = "", w99 = "", e8 = (nU == 8 && fm && !scale) ? "ي" : "";
            if (fm && !scale) { [tU, t11, t11[0], t11[1], t11[2]] = [[...tF], [...tF], "عشرة", "إحدى", "اثنتا"]; if (n99 > 20) tU[1] = "إحدى"; }
            if (n100) { if (n100 > 2) w100 = tF[n100] + miah; else if (n100 == 1) w100 = miah; else w100 = miah.slice(0, -1) + (!n99 || legal == "on" ? "تا" : "تان"); }
            if (n99 > 19) w99 = tU[nU] + (nU ? SpWa : "") + (n10 == 2 ? "عشر" : tF[n10]) + "ون";
            else if (n99 > 10) w99 = t11[nU] + e8 + " " + t11[0]; else if (n99 > 2) w99 = tU[n99] + e8; let nW = w100 + (n100 && n99 ? SpWa : "") + w99;
            if (!scale) return nW; if (!n99) return nW + " " + scale; if (n99 > 2) return nW + " " + (n99 > 10 ? scale + (last ? "" : "ًا")
                : (sPos < 3 ? [, "آلاف", "ملايين"][sPos] : tS[sPos] + "ات")); nW = (n100 ? w100 + ((legal == "on" && n99 < 3) ? " " + scale : "") + SpWa : "") + scale;
            return (n99 == 1) ? nW : nW + (last ? "ا" : "ان");
        }
    }
}
//=====================================================================
//==================== Common ISO Currency List in Arabic ===============
let tafqeetISOList = {
    AED: {
        uSingle: "درهم إماراتي", uDouble: "درهمان إماراتيان", uPlural: "دراهم إماراتية", uGender: "male",
        sSingle: "فلس", sDouble: "فلسان", sPlural: "فلوس", sGender: "male", fraction: 2
    },

    BHD: {
        uSingle: "دينار بحريني", uDouble: "ديناران بحرينيان", uPlural: "دنانير بحرينية", uGender: "male",
        sSingle: "فلس", sDouble: "فلسان", sPlural: "فلوس", sGender: "male", fraction: 3
    },

    EGP: {
        uSingle: "جنيه مصري", uDouble: "جنيهان مصريان", uPlural: "جنيهات مصرية", uGender: "male",
        sSingle: "قرش", sDouble: "قرشان", sPlural: "قروش", sGender: "male", fraction: 2
    },

    EUR: {
        uSingle: "يورو", uDouble: "يوروان", uPlural: "يوروات", uGender: "male",
        sSingle: "سنت", sDouble: "سنتان", sPlural: "سنتات", sGender: "male", fraction: 2
    },

    GBP: {
        uSingle: "جنيه إسترليني", uDouble: "جنيهان إسترلينيان", uPlural: "جنيهات إسترلينية", uGender: "male",
        sSingle: "بنس", sDouble: "بنسان", sPlural: "بنسات", sGender: "male", fraction: 2
    },

    INR: {
        uSingle: "روبية هندية", uDouble: "روبيتان هنديتان", uPlural: "روبيات هندية", uGender: "female",
        sSingle: "بيسة", sDouble: "بيستان", sPlural: "بيسات", sGender: "female", fraction: 2
    },

    IQD: {
        uSingle: "دينار عراقي", uDouble: "ديناران عراقيان", uPlural: "دنانير عراقية", uGender: "male",
        sSingle: "فلس", sDouble: "فلسان", sPlural: "فلوس", sGender: "male", fraction: 2
    },

    JOD: {
        uSingle: "دينار أردني", uDouble: "ديناران أردنيان", uPlural: "دنانير أردنية", uGender: "male",
        sSingle: "فلس", sDouble: "فلسان", sPlural: "فلوس", sGender: "male", fraction: 3
    },

    KWD: {
        uSingle: "دينار كويتي", uDouble: "ديناران كويتيان", uPlural: "دنانير كويتية", uGender: "male",
        sSingle: "فلس", sDouble: "فلسان", sPlural: "فلوس", sGender: "male", fraction: 3
    },

    LBP: {
        uSingle: "ليرة لبنانية", uDouble: "ليرتان لبنانيتان", uPlural: "ليرات لبنانية", uGender: "female",
        sSingle: "قرش", sDouble: "قرشان", sPlural: "قروش", sGender: "male", fraction: 2
    },

    LYD: {
        uSingle: "دينار ليبي", uDouble: "ديناران ليبيان", uPlural: "دنانير ليبية", uGender: "male",
        sSingle: "درهم", sDouble: "درهمان", sPlural: "دراهم", sGender: "male", fraction: 3
    },

    MAD: {
        uSingle: "درهم مغربي", uDouble: "درهمان مغربيان", uPlural: "دراهم مغربية", uGender: "male",
        sSingle: "سنتيم", sDouble: "سنتيمان", sPlural: "سنتيمات", sGender: "male", fraction: 2
    },

    OMR: {
        uSingle: "ريال عماني", uDouble: "ريالان عمانيان", uPlural: "ريالات عمانية", uGender: "male",
        sSingle: "بيسة", sDouble: "بيستان", sPlural: "بيسات", sGender: "female", fraction: 3
    },

    QAR: {
        uSingle: "ريال قطري", uDouble: "ريالان قطريان", uPlural: "ريالات قطرية", uGender: "male",
        sSingle: "درهم", sDouble: "درهمان", sPlural: "دراهم", sGender: "male", fraction: 2
    },

    SAR: {
        uSingle: "ريال سعودي", uDouble: "ريالان سعوديان", uPlural: "ريالات سعودية", uGender: "male",
        sSingle: "هللة", sDouble: "هللتان", sPlural: "هللات", sGender: "female", fraction: 2
    },

    SDG: {
        uSingle: "جنيه سوداني", uDouble: "جنيهان سودانيان", uPlural: "جنيهات سودانية", uGender: "male",
        sSingle: "قرش", sDouble: "قرشان", sPlural: "قروش", sGender: "male", fraction: 2
    },

    SOS: {
        uSingle: "شلن صومالي", uDouble: "شلنان صوماليان", uPlural: "شلنات صومالية", uGender: "male",
        sSingle: "سنت", sDouble: "سنتان", sPlural: "سنتات", sGender: "male", fraction: 2
    },

    SSP: {
        uSingle: "جنيه جنوب سوداني", uDouble: "جنيهان جنوب سودانيان", uPlural: "جنيهات جنوب سودانية", uGender: "male",
        sSingle: "قرش", sDouble: "قرشان", sPlural: "قروش", sGender: "male", fraction: 2
    },

    SYP: {
        uSingle: "ليرة سورية", uDouble: "ليرتان سوريتان", uPlural: "ليرات سورية", uGender: "female",
        sSingle: "قرش", sDouble: "قرشان", sPlural: "قروش", sGender: "male", fraction: 2
    },

    TND: {
        uSingle: "دينار تونسي", uDouble: "ديناران تونسيان", uPlural: "دنانير تونسية", uGender: "male",
        sSingle: "مليم", sDouble: "مليمان", sPlural: "ملاليم", sGender: "male", fraction: 3
    },

    USD: {
        uSingle: "دولار أمريكي", uDouble: "دولاران أمريكيان", uPlural: "دولارات أمريكية", uGender: "male",
        sSingle: "سنت", sDouble: "سنتان", sPlural: "سنتات", sGender: "male", fraction: 2
    },

    YER: {
        uSingle: "ريال يمني", uDouble: "ريالان يمنيان", uPlural: "ريالات يمنية", uGender: "male",
        sSingle: "فلس", sDouble: "فلسان", sPlural: "فلوس", sGender: "male", fraction: 2
    },

    //==== add here

}; // end of list


export const ResidencySponsorType = [
    {
        id: 1,
        name: "inside school",
        ar_name: "داخل المدرسه"
    },
    {
        id: 2,
        name: "outside school",
        ar_name: "خارج المدرسه"
    }

]

export const employeeStatus = [
    {
        id: 1,
        name: "Active",
        ar_name: "نشط"
    },
    {
        id: 2,
        name: "left school",
        ar_name: "غادر المدرسه"
    }
]

export const storeStatus = [
    {
        id: 0,
        name: "Not Active",
        ar_name: "غير نشط"
    },
    {
        id: 1,
        name: "Active",
        ar_name: "نشط"
    }

]

export const classGenders = [
    {
        id: 1,
        name: "Boys",
        ar_name: "اولاد فقط"
    },
    {
        id: 2,
        name: "Girls",
        ar_name: "بنات فقط"
    },
    {
        id: 3,
        name: "Mixed",
        ar_name: "مختلط"
    }

]

export const weeksDaysAr = ['الأحد',
    "الاثنين",
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
]



export const weekDaysEn = [
    "Sunday",
    "Monday",
    "Tuesday",
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'

]

export const genders = [{
    name: "ذكر",
    id: 0,
    ar_name: "male"
},
{
    name: "ذكر",
    id: 0,
    ar_name: "male"
}]
// function that convert en-number to ar numbers ex: 12k3 => ۱۲k۳
export const getArNumber = (str) => {
    if(!str) return str;
    return str?.toString()?.replace(/\d/g, function (v) {
        return String.fromCharCode(v.charCodeAt(0) + 0x0630);
    });
};

export const certificateTypes = [
    {
        id: 1,
        name: "Certification",
        ar_name: 'شهاده',
    },
    {
        id: 2,
        name: "Skill Certification",
        ar_name: 'شهاده مهارات'
    },
]

export const certificateDesigns = [
    {
        id: 1,
        name: "KG1_to_KG2",
        ar_name: "KG1_to_KG2"
    },
    {
        id: 2,
        name: "G1_to_G2",
        ar_name: "G1_to_G2"
    },
    {
        id: 3,
        name: "G3_to_G4",
        ar_name: "G3_to_G4"
    },
    {
        id: 4,
        name: "G5_to_G8",
        ar_name: "G5_to_G8"
    },
    {
        id: 5,
        name: "G9_to_G12",
        ar_name: "G9_to_G12"
    },
]
export const HonoredStudentTypes = [
    {
        id: 3,
        name: "Honor 90% to 100%",
        ar_name: "Honor 90% to 100%"
    },
    {
        id: 1,
        name: "Honor 90% to 94.9%",
        ar_name: "Honor 90% to 94.9%"
    },
    {
        id: 2,
        name: "Out Standing 95% to 100%",
        ar_name: "Out Standing 95% to 100%"
    }
]

export function splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [],
        i = 0,
        n = arr?.length;
    while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
}


export const certificateShow = [
    {
        id: 0,
        name: "No",
        ar_name: "لا"
    },
    {
        id: 1,
        name: "Yes",
        ar_name: "نعم"
    },

]

export const vaccinesList = [
    {
        id: 0,
        name: "TD (Grade 5)",
        ar_name: "الدفتيريا و التيتاتوتس (درجة 5)",
    },
    {
        id: 1,
        name: "TD (Grade 12)",
        ar_name: "الدفتيريا و التيتاتوتس (درجة 12)",
    },
    {
        id: 2,
        name: "Other Vaccines",
        ar_name: "تطعيمات اخري",
    },
];

export const EssentialToBEAddToTotalMark = [
    {
        id: 0,
        name: "No",
        ar_name: "لا"
    },
    {
        id: 1,
        name: "Yes",
        ar_name: "نعم"
    },

]

export const unwindArr = (arr, key) => {
    if (!arr?.length) {
        return arr
    }
    
    else {
        if (!Object.keys(arr[0]).includes(key)) {
            return arr
        }
        const result = [];
        arr?.forEach(item => {
          if (Array.isArray(item[key]) && item[key]?.length > 0 ) {
            item[key].forEach(subItem => {
              const newItem = { ...item };
              newItem[key] = subItem;
              result.push(newItem);
            });
          } else {
            result.push(item);
          }
        });
      
        return result;
    }
}

export const highReportsList = [
    {
        id: 1,
        name: "Detailed data for students expected to graduate",
        ar_name: "البيانات التفصيلية ",
    },
    {
        id: 2,
        name: "graduates with the number of years of study"
        ,
        ar_name: "كشف الخرجين مع عدد سنوات الدراسة",
    },
    {
        id: 3,
        name: "Detailed data for students expected to graduate",
        ar_name: "نموذج احصائية الطلبة المتوقع تخرجهم",
    },
    {
        id: 4,
        name: "Detected in cumulative order",
        ar_name: "كشفوف بترتيب المعدل التراكمي ",
    },
    {
        id: 5,
        name: "The number of students with the GPA",
        ar_name: "عدد الطلبة حسب التراكمي",
    },
    // {
    //     id: 6,
    //     name: "Final Grading Listing",
    //     ar_name: "Final Grading Listing",
    // },
];
//  this for check quill text editor content empty or not 
export function areEmpty(...args) {
    for (const arg of args) {
        if (!arg) {
            return true;
        } if (arg === '<div><br></div>') {
            return true
        }
    }
    return false;
}

const sunEditorOptions = {
    buttonList: [
        ["undo", "redo"],
        ["font", "fontSize"],
        // ['paragraphStyle', 'blockquote'],
        [
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript"
        ],
        ["fontColor", "hiliteColor"],
        ["align", "list", "lineHeight"],
        ["outdent", "indent"],

        ["table", "horizontalRule", "link", "image", "video"],
        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
        ['imageGallery'], // You must add the "imageGalleryUrl".
        // ["fullScreen", "showBlocks", "codeView"],
        ["preview", "print"],
        ["removeFormat"]

        // ['save', 'template'],
        // '/', Line break
    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
    defaultTag: "div",
    minHeight: "300px",
    rtl: false,
    showPathLabel: false,
    imageGalleryUrl: `${LP_API_BASE_URL}/images/image-gallary`,
    font: [
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        "Arial",
        "Comic Sans MS",
        "Courier New",
        "Impact",
        "Georgia",
        "Tahoma",
        "Trebuchet MS",
        "Verdana",
    ].sort()
}


export const sunEditorArOptions = { ...sunEditorOptions, rtl: true, className: 'suneditor-rtl' };
export const sunEditorEnOptions = { ...sunEditorOptions, className: 'suneditor-ltr' };

export function getYoutubeVideoId(url) {
    var videoId = url?.split('v=')[1];
    var ampersandPosition = videoId?.indexOf('&');
    if (ampersandPosition != -1) {
        videoId = videoId?.substring(0, ampersandPosition);
    }
    return videoId || "";
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}


export const booksTypes = [{ id: 1, name: 'Story', ar_name: "قصة" }, { id: 2, name: 'Book', ar_name: "كتاب" }]
export const storeBookTypes = [{ id: 1, name: 'Paper Book', ar_name: "Paper Book" }, { id: 2, name: 'Digital', ar_name: "Digital" }, { id: 3, name: 'Bundle', ar_name: "Bundle" }]
export const penaltyTypes = [{ id: 0, name: 'with penalty', ar_name: "خصم" }, { id: 1, name: 'without penalty', ar_name: "بدون خصم" }]
export const punishmentApplyType = [{id:1 ,name:"Alert" , ar_name:"تنبيه"} , {id:2 ,name:"Warning" , ar_name:"انذار"},{id:3 ,name:"Warning Repeat" , ar_name:"تكرار الانذار"} , {id:4 ,name:"25%" , ar_name:"25%"} ,{id:5 ,name:"50%" , ar_name:"50%"} ,{id:6 ,name:"100%" , ar_name:"100%"} ,{id:7 ,name:"200%" , ar_name:"200%"}]
export const clothesTypes = [{id:1, ar_name:"ولد", name:"Boy"}, {id:2, ar_name:"بنت", name:"Girl"}, {id:3, ar_name:"ولد/بنت", name:"Boy/Girl"}];

export const getPermisstion = (userData) =>{
    const slug = window.location.href.replace(/\?.+/g, "").split('/')?.at(-1)
    const PermissionsPages  = userData?.roles?.groups?.find(group => group?.PermissionsPages?.some(page => page?.slug === slug) )?.PermissionsPages ;
    const studentListPermissionsPage  = PermissionsPages?.find(page => page?.slug === slug)?.Permissions ;
    const  permisttion = studentListPermissionsPage?.map(p => p?.slug);
    return permisttion
}

export const getTeacherGradesAndClasses = (schoolSystemSubjectTeacherList,classesList,gradesList, id) =>{
    const teachersubjectes = schoolSystemSubjectTeacherList?.find(subject => subject?.teacher_id === id);
    const allTeacherClassesIds = teachersubjectes?.TeachersClassesSubjects?.map(subject => subject?.school_class_id);
    const allTeacherGradeIds = teachersubjectes?.TeachersClassesSubjects?.map(subject => subject?.school_grade_id);
    const teacherClasses = classesList?.filter(schoolClass => [...new Set(allTeacherClassesIds)]?.includes(schoolClass?.id));
    const teacherGrades = gradesList?.filter(schoolClass => [...new Set(allTeacherGradeIds)]?.includes(schoolClass?.id));
    return {teacherClasses, teacherGrades}
}

export const dailyCollectorReportsTypes = [{id:1, ar_name:"مبيعات", name:"Sales"}, {id:2, ar_name:"مرتجعات", name:"Returns"}]
export const movmentTyps = [{id:1, ar_name:"بيع", name:"sale"}, {id:2, ar_name:"إسترجاع", name:"refund"},{id:3, ar_name:"شراء", name:"purchase"}]
export const stationeryMovmentTyps = [{id:1, ar_name:"تسليم قرطاسية", name:"Deliver Stationery"}, {id:2, ar_name:"إسترجاع", name:"refund"},{id:3, ar_name:"شراء", name:"purchase"}]


export const DateFormat = ({date}) =>{
    return <span>
        {parser(moment(date)?.locale('en')?.format('MMMM Do YYYY')?.toString().replace(/st|rd|nd|th/g,(match) => `<sup>${match}</sup>,`))}
    </span>
}

export const voucherStatus = [{id:1, name:'deported', ar_name:'مرحل' }, {id:2, name:'not deported', ar_name:'غير مرحل' }];
export const voucherTypes = [{id:1, name:'Creditor', ar_name:'دائن' }, {id:2, name:'debtor', ar_name:'مدين' }];

// this data cutmization function custmize tree fields name to be used eith antd tree component 
export const treeDataCutimazation = (tree, locale) => {
    return tree?.map((leaf) => {
      if (!leaf?.children) {
        return {
          value: leaf?.id,
          title:
            locale === 'ar'
              ? leaf?.ar_name + ' ' + (leaf?.account_number ?? '')
              : leaf?.name + ' ' + (leaf?.account_number ?? ''),
        };
      } else {
        return {
          value: leaf?.id,
          title:
            locale === 'ar'
              ? leaf?.ar_name + ' ' + (leaf?.account_number ?? '')
              : leaf?.name + ' ' + (leaf?.account_number ?? ''),
          children: treeDataCutimazation(leaf?.children),
        };
      }
    });
  };


export const expandTree = (data, locale) =>{
    const dataList = [];
    const generateList = (data, locale, level) => {
        for (let i = 0; i < data.length; i++) {
          const node = data[i];
          const { id = '', name = '' , ar_name = '', account_number = '', is_sub_account = '' } = node;
          dataList.push({
            ...node,
            key :id,
            title: (locale === 'ar' ? ar_name  : name) + `(${account_number ?? ''})`,
            is_sub_account,
            level
          });
          if (node.children) {
            generateList(node.children,locale, level + 1);
          }
        }
      };
      
      generateList(data, locale, 1)
     
    return dataList
}


export const langSkilssLevels = [
    { id: 1, name: 'Excellent', ar_name: 'ممتاز'},
    { id: 2, name: 'Good', ar_name: 'جيد'},
    { id: 3, name: 'Fair', ar_name: 'متوسط'},
    { id: 4, name: 'Basic', ar_name: 'مقبول'}
  ];


export const getNextAcademicYearGradeId = (gradeId,gradesList, nextYeargrades) =>{
    const currentGradeOrder = gradesList?.find(el =>el?.id === gradeId)?.sort_order ;
    const nextGradeId = nextYeargrades?.find(el =>el?.sort_order === currentGradeOrder)?.id ;
    return nextGradeId || ''
}

export const getNextAcademicYearClassId = (classId, classes, nextYearclasses) =>{
    const currentClassName = classes?.find(el =>el?.id === classId)?.name ;
    const nextClassId = nextYearclasses?.find(el => el?.name === currentClassName)?.id ;
    return nextClassId || ''
}


export   const yearStatus = [
    {
      id: 0,
      name: 'still study',
      ar_name: 'مستمر'
    },
    {
      id: 1,
      name: 'Successful',
      ar_name: 'ناجح'
    },
    {
      id: 2,
      name: 'Fail',
      ar_name: 'راسب'
    },
  ];

  export const CASH_VOUCHER = 1;
  export const CHECK_VOUCHER = 2;
  export const CREDITOR = 1
  export const DEBTOR = 2

 export  const speaking = [
    { id: 1, name: 'Speaking_Good'},
    { id: 0, name: 'Speaking_Not_Good' },
  ];
 export  const leaters = [
    { id: 1, name: 'Letters_Good'},
    { id: 0, name: 'Letters_Not_Good'},
  ];
 export  const composeSentences = [
    { id: 0, name: 'Compose_Sentence_Well'},
    { id: 1, name: 'Compose_Sentence_Bad'},
    { id: 2, name: 'Compose_Simple_Sentence'}
  ];
 export  const muscleGrow = [
    { id: 1, name: 'Good'},
    { id: 0, name: 'Bad'}
  ];
 export  const overAllHealth = [
    { id: 1, name: 'Good'},
    { id: 0, name: 'NoT_Good'},
  ];
 export  const lingustics = [
    { id: 1, name: 'Exits'},
    { id: 0, name: 'Not_Exits'},
    { id: 2, name: 'Not_Required'},
  ];
 export  const generalInfo = [
    { id: 1, name: 'Yes'},
    { id: 0, name: 'No'},
    { id: 2, name: 'Not_Required'},
  ];
 export  const emotionalAspectsoptions = [
    {
      label: 'Social',
      value: '1',
    },
    {
      label: 'Stubborn',
      value: '2',
    },
    {
      label: 'Shy',
      value: '3',
    },
    {
      label: 'Nervous',
      value: '4',
    },
    {
      label: 'Possessive_Lover',
      value: '5',
    },
    {
      label: 'Quiet',
      value: '6',
    },
    {
      label: 'Aggresive',
      value: '7',
    }
  ];

 export  const interviewResult = [
    { id: 1, name: 'Accepted' },
    { id: 0, name: 'Not_Accepted' },
    { id: 2, name: 'Reinterview' },
  ];
 export  const interviewFinalResult = [
    { id: 1, name: 'Excellent' },
    { id: 0, name: 'Good' },
  ];


  export const calcBMI = (weight, length) =>{
    if(!weight || !length){
        return ''
    }
    return (Math.ceil(((weight)/(Math.pow(length,2) * 0.0001)).toFixed(2) *10 )/ 10)?? ''
  } 

  export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

export const getType = (type) =>{
    switch(type){
      case 'JournalVoucher':
        return 'سند قيد'
      case 'CashVoucher':
        return 'سند صرف نقدي'
      case 'BankChequeVoucher':
        return 'سند صرف شيك'
      default:
        return ''
    }
  }

  export function groupBy(field, arr, len) {

    const result = [];

    const group = {};

    arr.forEach(el => {
      const groupId = el[field];

      if (!group[groupId]) {
        group[groupId] = [];
      }

      group[groupId].push(el);

      if (group[groupId].length >= len) {
        result.push(group[groupId]);
        group[groupId] = [];
      }
    });

    for (const groupId in group) {
      if (group[groupId].length > 0) {
        result.push(group[groupId]);
      }
    }
    return result
}

// Format with commas for thousands separator and three decimal places
export const formattedNumber = (number) => {
  return isNaN(Number(number)) ? number : Number(number).toLocaleString('en-US', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
}

var
persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

export const fixNumbers = (str) => {
  if(typeof str === 'string')
  {
    for(var i=0; i<10; i++)
    {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
};


export const UPDATED = 'updated'
export const CREATED = 'created'
export const DELETED = 'deleted'

export const POST = 'POST'; 
export const DELETE = 'DELETE'; 
export const GET = 'GET'; 
export const UPDATE = 'UPDATE'; 
export const PATCH = 'PATCH'; 
export const LANSCAPE = 'LANSCAPE'; 
export const PORTRATE = 'PORTRATE'; 

export function extractBirthdate(civilID) {
    // Extract century indicator, year, month, and day from the civil ID
    const centuryIndicator = parseInt(civilID[0], 10);
    const year = parseInt(civilID.substring(1, 3), 10);
    const month = parseInt(civilID.substring(3, 5), 10);
    const day = parseInt(civilID.substring(5, 7), 10);
  
    // Determine the century based on the century indicator
    let century;
    if (centuryIndicator === 2 || centuryIndicator === 3) {
      century = 1900 + (centuryIndicator - 2) * 100;
    } else {
      throw new Error("Invalid century indicator in civil ID");
    }
  
    // Construct the full year
    const fullYear = century + year;
  
    // Format the birthdate as YYYY/MM/DD
    const birthdate = `${fullYear}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}`;
  
    return birthdate;
  }

export const setSearchParams = (params = {}) =>{
    if(!params) return;
    const filteredParams = Object.fromEntries(
      Object.entries(params)?.filter(([key, value]) => value !== null && value !== undefined && value !== ""));
    const searchParams = new URLSearchParams(filteredParams);

    // Construct the new URL
    const newUrl = `${window.location.href.replace(/\?.+/g, "")}?${searchParams.toString()}`;

    // Update the browser's URL without reloading the page
    window.history.pushState({}, "", newUrl);
  }