export const SHARED_SCHOOL_YEAR = 'SHARED_SCHOOL_YEAR';
export const SHARED_GRADES_TYPES = 'SHARED_GRADES_TYPES';
export const SHARED_ROLES = 'SHARED_ROLES';
export const SHARED_CLASSES = 'SHARED_CLASSES';
export const SHARED_CLASSES_ALL = 'SHARED_CLASSES_ALL';
export const SHARED_GRADES = 'SHARED_GRADES';
export const SHARED_ALL_GRADES = 'SHARED_ALL_GRADES';
export const SHARED_ALL_CLASSES = "SHARED_ALL_CLASSES";
export const SHARED_DOCUMENTS_TYPE = 'SHARED_DOCUMENTS_TYPE';

export const SHARED_COUNTRY = 'SHARED_COUNTRY';
export const SHARED_RELIGION = 'SHARED_RELIGION';
export const SHARED_GRADUATION_TYPE = 'SHARED_GRADUATION_TYPE';
export const SHARED_PASSPORT_TYPE = 'SHARED_PASSPORT_TYPE';
export const SHARED_SUBJECTS_LIST = 'SHARED_SUBJECTS_LIST';
export const SHARED_DEGREE_TYPE = 'SHARED_DEGREE_TYPE';
export const SHARED_EXPERIENCE_TYPE = 'SHARED_EXPERIENCE_TYPE';
export const SHARED_CONTRACT_PERIOD = 'SHARED_CONTRACT_PERIOD';
export const SHARED_BANK_LIST = 'SHARED_BANK_LIST';

export const SHARED_ABSENTREASON_LIST = 'SHARED_ABSENTREASON_LIST';
export const SHARED_GET_FEESTYPE = 'SHARED_GET_FEESTYPE';
export const FEES_LIST_FOR_STUDENT = 'FEES_LIST_FOR_STUDENT';
export const RESET_FEES_LIST_FOR_STUDENT = 'RESET_FEES_LIST_FOR_STUDENT';


export const GET_PERCENTAGE_LIST = 'GET_PERCENTAGE_LIST';
export const GET_BROTHERPERCENTAGE_LIST = 'GET_BROTHERPERCENTAGE_LIST';
export const SHARED_GET_EMPLOYEE_LIST = 'SHARED_GET_EMPLOYEE_LIST';

export const SHARED_GET_MASTER_FEES = 'SHARED_GET_MASTER_FEES';

export const SHARED_GET_SCHOOL_SECTIONS_LIST = 'SHARED_GET_SCHOOL_SECTIONS_LIST';
export const SHARED_GET_SCHOOL_DEPARTMENTS_LIST = 'SHARED_GET_SCHOOL_DEPARTMENTS_LIST';
export const SHARED_GET_SCHOOL_TEACHERS_LIST = 'SHARED_GET_SCHOOL_TEACHERS_LIST';
export const SHARED_GET_SCHOOL_SUBJECTS_LIST = 'SHARED_GET_SCHOOL_SUBJECTS_LIST';
export const SHARED_GET_SCHOOL_SUBJECTS_LIST_ALL = 'SHARED_GET_SCHOOL_SUBJECTS_LIST_ALL';
export const SHARED_GET_SCHOOL_PERIODICALS_LIST = 'SHARED_GET_SCHOOL_PERIODICALS_LIST';
export const SHARED_GET_SCHOOL_SUBJECTS_LIST_BY_TEACHER = 'SHARED_GET_SCHOOL_SUBJECTS_LIST_BY_TEACHER';
export const STUDENT_TYPES = "STUDENT_TYPES";
export const SHARED_NEXT_YEAR_GRADES = "SHARED_NEXT_YEAR_GRADES";
export const GET_CLASSES_WITH_COUNT = "GET_CLASSES_WITH_COUNT";
export const GET_DAMAGED_BOOKS = "GET_DAMAGED_BOOKS";
export const NEXT_YEAR_SHARED_CLASSES = 'NEXT_YEAR_SHARED_CLASSES';
export const GET_NEXT_YEAR_SUBJECTS_LIST = 'GET_NEXT_YEAR_SUBJECTS_LIST';
export const GET_PREVIOUSE_GRADES = 'GET_PREVIOUSE_GRADES';
export const UPDATE_INTERNET_SPEED = 'UPDATE_INTERNET_SPEED';
export const GET_SHARED_REASONS = 'GET_SHARED_REASONS';
export const GET_FEES = 'GET_FEES';
export const GET_NEXT_YEAR_FEES = 'GET_NEXT_YEAR_FEES';
export const GET_SHARED_ABSENCE_PERIODICAL = 'GET_SHARED_ABSENCE_PERIODICAL';
