import { GET_REGISTRATION_STATSTICS, GET_NEW_STUDENT_STATISTICS } from '../constants/Dashboard';

const initState = {
  registrationStatistics: {},
  newStudentStatistics:{}
};

const dashboard = (state = initState, action) => {
  switch (action.type) {
    case GET_REGISTRATION_STATSTICS: {
      return {
        ...state,
        registrationStatistics: action?.payload || [],
      };
    }
    case GET_NEW_STUDENT_STATISTICS: {
      return {
        ...state,
        newStudentStatistics: action?.payload || {},
      };
    }
    
    default:
      return state;
  }
};

export default dashboard;
