import {
	GET_ACCOUNTS_TREE,
	GET_COST_CENTER_TREE,
	GET_CURRENCY,
	GET_JOURNAL_VOUCHERS,
	GET_CHEQUE_BOOK,
	GET_BANK_VOUCHER,
	GET_CASH_VOUCHER,
	GET_ACCOUNT_STATMENT_REPORT,
  GET_VOUCHERS_REVIEW_REQUESTS,
	GET_ACCOUNT_MOTION_REPORT,
	GET_ACCOUNT_TRIAL_REPORT,
	GET_REVENUE_EXPENSE,
	GET_ACCOUNT_REPORTS,
	GET_DETAILS_REPORT
} from '../constants/AccountArea';

const initState = {
    accountsTree:[],
	costCenterTree:[],
	journalVouchers:[],
	currencies:[],
	chequeBooks:[],
	bankVouchers:[],
	vouchersReviewRequest:[],
	cashVouchers:[],
	accountStatmentReport:{},
	accountMotionReport:{},
	accountTrialReport:{},
	revenueExpense:{},
	accountReports:{},
	detailsReport:[]
}

const Account = (state = initState, action) => {
	 
	switch (action.type) {
		case GET_ACCOUNTS_TREE: {
			return {
				...state,
				accountsTree: action?.payload?.schoolAccountsTree || [],
			}
		}
		case GET_COST_CENTER_TREE: {
			return {
				...state,
				costCenterTree: action?.payload?.schoolCostCenters || [],
			}	
		}
		case GET_JOURNAL_VOUCHERS:{
			return {
				...state,
				journalVouchers: action?.payload?.response?.result || [],
				journalVouchersTotalItems: action?.payload?.response?.totalItems || [],

			}	
		}
		case GET_CURRENCY:{
			return {
				...state,
				currencies: action?.payload?.data || [],
			}	
		}
		case GET_CHEQUE_BOOK:{
			return {
				...state,
				chequeBooks: action?.payload?.chequeBooks || [],
				chequeBooksTotalItems: action?.payload?.response?.totalItems || [],
				
			}	
		}
		case GET_BANK_VOUCHER:{
			return {
				...state,
				bankVouchers: action?.payload?.response?.result || [],
				bankVouchersTotalItems: action?.payload?.response?.totalItems || [],

			}	
		}
		case GET_VOUCHERS_REVIEW_REQUESTS:{
			return {
				...state,
        vouchersReviewRequest: action?.payload?.data?.items || [],
        vouchersReviewRequestTotalItems: action?.payload?.data?.totalItems || [],
			}
		}
		case GET_CASH_VOUCHER:{
			return {
				...state,
				cashVouchers: action?.payload?.cashVouchers || [],
				cashVouchersTotalItems: action?.payload?.response?.totalItems || [],

			}
		}
		case GET_ACCOUNT_STATMENT_REPORT:{
			return {
				...state,
				accountStatmentReport: action?.payload?.data || {},
			}
		}
		case GET_ACCOUNT_MOTION_REPORT:{
			return {
				...state,
				accountMotionReport: action?.payload?.data || {},
			}
		}
		case GET_ACCOUNT_TRIAL_REPORT:{
			return {
				...state,
				accountTrialReport: action?.payload?.data || {},
			}
		}
		case GET_REVENUE_EXPENSE:{
			return {
				...state,
				revenueExpense: action?.payload?.data || {}
			}
		}
		case GET_ACCOUNT_REPORTS:{
			return {
				...state,
				accountReports: action?.payload?.data || {}
			}
		}
		case GET_DETAILS_REPORT:{
			return {
				...state,
				detailsReport: action?.payload?.data || [],
				
			}
		}
		default:
			return state;
	}
}

export default Account