
import {
    GET_CLOTHES_STORE_CATEGORIES,
    GET_CLOTHES_DISTRIBUTERS,
    GET_CLOTHES,
    GET_PURCHASED_CLOTHES,
    GET_CLOTHES_SIZES,
    GET_SELLED_CLOTHES,
    GET_CLOTHES_WITH_COUNT,
    GET_REFUNDED_CLOTHES,
    GET_PROPOSAL_REQUEST,
    GET_DAILY_COLLECTOR_REPORT,
    GET_DETAILS_COLLECTOR_REPORT,
    GET_CLOTHE_SOLD_RETURN_REPORT,
    GET_INVENTORT_CLOTHES_REPORT,
    GET_CLOTHES_DISTRIBUTERS_REPORTS,
    GET_PRODUCT_MOVMENT_REPORTS
} from '../constants/ClothesArea';

const initState = {
    clothesCategories: [],
    clothesDistributesr :[],
    clothes:[],
    purchasedClothes:[],
    sizes:[],
    selledClothes:[],
    clothesWithAvailableCount:[],
    refundedClothes:[],
    proposalRequest:[],
    dailyCollectorReport:{},
    detailedCollectorReport:[],
    clotheSoldReturnReport:{},
    inventoryClothesReport:[],
    dirtubuterClothesReports:[],
    productMovemnt:[]
}

const  ClothesArea = (state = initState, action) => {
    switch (action.type) {
        case GET_CLOTHES_STORE_CATEGORIES:
            return {
                ...state,
                clothesCategories: action?.payload?.data?.result
            }
        case GET_CLOTHES_DISTRIBUTERS:
            return {
                ...state,
                clothesDistributesr: action?.payload?.data?.result
            }
        case GET_CLOTHES:
            return{
                ...state,
                clothes: action?.payload?.data?.result
            }
        case GET_PURCHASED_CLOTHES:
            return {
                ...state,
                purchasedClothes: action.payload.data.result
            }
        case GET_CLOTHES_SIZES:
            return{
                ...state,
                sizes: action.payload.data
            }
        case GET_SELLED_CLOTHES:
            return{
                ...state,
                selledClothes: action.payload.data.result
            }
        case GET_CLOTHES_WITH_COUNT:
            return {
                ...state,
                clothesWithAvailableCount: action.payload.data
            }
        case GET_REFUNDED_CLOTHES:
            return {
                ...state,
                refundedClothes: action.payload.data?.result
            }
        case GET_PROPOSAL_REQUEST:
            return {
                ...state,
                proposalRequests: action.payload.data?.result
            }
        case GET_DAILY_COLLECTOR_REPORT:
            return {
                ...state,
                dailyCollectorReport: action.payload.data,
            }
        case GET_DETAILS_COLLECTOR_REPORT:
            return {
                ...state,
                detailedCollectorReport: action.payload.data?.result
            }

        case GET_CLOTHE_SOLD_RETURN_REPORT:
            return {
                ...state,
                clotheSoldReturnReport:action.payload.data
            }
        case GET_INVENTORT_CLOTHES_REPORT:
            return {
                ...state,
                inventoryClothesReport:action.payload.data ?? []
            }
        case GET_CLOTHES_DISTRIBUTERS_REPORTS:
            return {
                ...state,
                dirtubuterClothesReports:action.payload.data
            }
        case GET_PRODUCT_MOVMENT_REPORTS:
            return {
                ...state,
                productMovemnt: action.payload.data
            }
            
            default:      
        return state;
    }
}

export default ClothesArea ;