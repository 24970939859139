import React from "react";
import { Menu, Dropdown, Avatar, Image } from "antd";
import { connect, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons';
import {
  EditOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { signOut } from 'redux-thunk/actions/Auth'
import AppLocale from "lang";
const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "#/app/admin/school-data"
  },
];

export const NavProfile = ({ signOut }) => {
  const schoolData = useSelector(state => state.adminarea.schoolData);
  const locale = useSelector(state => state.theme.locale);
  const currentAppLocale = (title) => AppLocale[locale]?.messages[title];
  const { userData } = useSelector(state => state.auth);
  let pos = localStorage.getItem('position');
  pos = userData?.positions?.find(p => p.id === parseInt(pos))?.position?.ar_name || '';
  pos = userData?.is_admin === 1 ? currentAppLocale('employee.is_admin') : pos;
  const profileImg = schoolData !== '' ? schoolData?.sch_logo : "img/logo.png" || '';
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar src={<Image src={profileImg} style={{ objectFit: "contain" }} />} />
          <div className="pl-3">
            <h6 className="mb-0">{userData !== '' ? locale === 'ar' ? userData?.ar_full_name : userData?.full_name : ''}</h6>
            <span className="text-muted">{schoolData !== '' ? locale === 'ar' ? pos : pos : '' || ''}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })} */}
          <Menu.Item key={menuItem.length + 1} onClick={() => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">{currentAppLocale('Sign.Out')}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <div style={{ width: 150 }}>
      <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="profile">
            <span><UserOutlined className="nav-icon mr-0" /></span>&nbsp; <span>{currentAppLocale('Sign.Out')}</span>
          </Menu.Item>
        </Menu>
      </Dropdown>
    </div>
  );
};

export default connect(null, { signOut })(NavProfile)
