import {
    GET_ACCOUNT_AREA,
    GET_STORE_CATEGORY,
    GET_DISTRIBUTERS,
    GET_PUBLISHERS,
    GET_STORE_BOOKS,
    GET_BOOKS_DELIVERE_TO_TEACHER,
    GET_BOOK_WITH_AVAILABLE_COUNT,
    GET_BOOKS_DELIVERED_TO_STUDENT,
    GET_PURCHASED_BOOKS,
    GET_SELLED_BOOKS,
    GET_BOOKS_RECEIVED_FROM_STUDENT,
    GET_BOOKS_RECEIVED_FROM_TEACHER,
    GET_STORE_PURCHASE_BOOKS_REPORTS,
    GET_STORE_SOLID_BOOKS,
    GET_STORE_RECEIVED_BOOKS,
    GET_STORE_CLASSIFICATION
} from '../constants/StoreArea';


const initStateShared = {
    accountTree: [],
    storeCategories: [],
    distributers: [],
    publishers: [],
    books: [],
    booksDeliveredToTeacher: [],
    booksWithAvailableCounts: [],
    booksDeliveredToStudents: [],
    purchasedBooks: [],
    selledBooks: [],
    booksReceivedFromStudents:[],
    purchaseBooksReports:[],
    soldeBooksReports:[],
    storeBookClassification:[]
}

const storeArea = (state = initStateShared, action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_ACCOUNT_AREA:
            return {
                ...state,
                accountTree: payload?.data
            }
        case GET_STORE_CATEGORY:
            return {
                ...state,
                storeCategories: payload?.data.result
            }
        case GET_DISTRIBUTERS:
            return {
                ...state,
                distributers: payload?.data.result
            }
        case GET_PUBLISHERS:
            return {
                ...state,
                publishers: payload?.data.result
            }
        case GET_STORE_BOOKS:
            return {
                ...state,
                books: payload?.data.result
            }
        case GET_BOOKS_DELIVERE_TO_TEACHER:
            return {
                ...state,
                booksDeliveredToTeacher: payload?.data.result
            }

        case GET_BOOK_WITH_AVAILABLE_COUNT:
            return {
                ...state,
                booksWithAvailableCounts: payload.data
            }
        case GET_BOOKS_DELIVERED_TO_STUDENT:
            return {
                ...state,
                booksDeliveredToStudents: payload?.data.result
            }
        case GET_PURCHASED_BOOKS:
            return {
                ...state,
                purchasedBooks: payload?.data.result
            }
        case GET_SELLED_BOOKS:
            return {
                ...state,
                selledBooks: payload?.data.result
            }
        case GET_BOOKS_RECEIVED_FROM_STUDENT:
            return {
                ...state,
                booksReceivedFromStudents: payload?.data.result
            }
        case GET_BOOKS_RECEIVED_FROM_TEACHER:
        return {
            ...state,
            booksReceivedFromTeacher: payload?.data.result
        }
        case GET_STORE_PURCHASE_BOOKS_REPORTS:
            return {
                ...state,
                purchaseBooksReports: payload?.data
            }
        case GET_STORE_SOLID_BOOKS:
            return {
                ...state,
                soldBooksReports: payload?.data
            }
        case GET_STORE_RECEIVED_BOOKS:
            return {
                ...state,
                receivedBooksReports: payload?.data?.rows
            }
        case GET_STORE_CLASSIFICATION:
            return {
                ...state,
                storeBookClassification: payload?.data?.result
            }
            
        default:
            return state;
    }
}

export default storeArea;