
const initState = {
    list: []
}

const test = (state = initState, action) => {
    switch (action.type) {
        case "LIST_SUCCESS":
            return {
                ...state,
                list: action.payload
            }
            default:
        return state;
    }
    return state;
}

export default test;