import {

    GET_EMPLOYEE_DYNAMIC_REPORT_LIST,
    RESET_EMPLOYEE_DYNAMIC_REPORT,
    GET_EMPLOYEE_REPORT_COLUMN,
    GET_STUDENT_REPORT_COLUMN, GET_STUDENT_DYNAMIC_REPORT_LIST, GET_PRINT_REPORT_STUDENT_LIST,
    GET_PRINT_STUDENT_LIST,
    GET_PRINT_REPORT_EMPLOYEE_LIST,
    GET_REPORT_LIST,
    GET_PRINT_FEES_STU_REPORT_LIST,
    GET_PRINT_FEES_STUDENT_LIST
} from '../constants/DynamicReportArea';


const initState = {
    // Student Reports
    studentReportColumnList: [],
    studentReportsList: [],
    studentReportsStatus: false,
    studentReportsMsg: '',
    studentReportsTotalItems: 0,
    studentReportsCurrentPage: 0,
    // Student List for Print
    studentListPrint: [],
    studentListPrintTotalItems: 0,
    studentListPrintCurrentPage: 0,
    studentTableHeader: [],
    studentReportDownLoadURL: '',

    // for Employee Reports Listing
    employeeReports: [],
    employeeReportsStatus: false,
    employeeReportsMsg: '',
    employeeReportsTotalItems: 0,
    employeeReportsCurrentPage: 0,
    employeeReportColumnList: [],
    //for Employee Report Print Screen
    employeePrintList: [],
    employeePrintListStatus: false,
    employeePrintListMsg: '',
    employeePrintListTotalItems: 0,
    employeePrintListCurrentPage: 0,

    reportsList: [],

    // For Print-Student-Fees Page
    studentFeesReportList: [],
    currentYear: {},
    nextYear: {},
    feesReportStudentList: [],
    feesReportStudentListStatus: false,
    feesReportStudentListMsg: '',
    feesReportStudentListTotalItems: 0,
    feesReportStudentListCurrentPage: 0,
}


const DynamicReportArea = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_STUDENT_REPORT_COLUMN:
            return {
                ...state, studentReportColumnList: payload?.data || []
            }

        case GET_STUDENT_DYNAMIC_REPORT_LIST:
            return {
                ...state,
                studentReportsList: payload?.data?.result || [],
                studentReportsStatus: payload?.status || false,
                studentReportsMsg: payload?.msg || 'Something Went Wrong',
                studentReportsTotalItems: payload?.data?.totalItems || 0,
                studentReportsCurrentPage: payload?.data?.currentPage || 1,
                studentReportsOffset: payload?.data?.offset || 0,
            }
        case GET_PRINT_REPORT_STUDENT_LIST:
            return {
                ...state,
                studentReportsList: payload?.data || [],
            }

        case GET_PRINT_STUDENT_LIST:
            return {
                ...state,
                studentListPrint: payload?.data?.result || [],
                studentListPrintTotalItems: payload?.data?.totalItems || 0,
                studentListPrintCurrentPage: payload?.data?.currentPage || 0,
                studentTableHeader: payload?.data?.heading_name || [],
                studentReportDownLoadURL: payload?.data?.url || '',
            }

        case GET_EMPLOYEE_DYNAMIC_REPORT_LIST:
            return {
                ...state,
                // schoolYear: payload
                employeeReports: payload?.data?.result || [],
                employeeReportsStatus: payload?.status || false,
                employeeReportsMsg: payload?.msg || 'Something Went Wrong',
                employeeReportsTotalItems: payload?.data?.totalItems || 0,
                employeeReportsCurrentPage: payload?.data?.currentPage || 0,
            }
        case GET_EMPLOYEE_REPORT_COLUMN:
            return {
                ...state,
                employeeReportColumnList: payload?.data || [],
            }
        case RESET_EMPLOYEE_DYNAMIC_REPORT:
            return {
                ...state,
                employeeReports: [],
                employeeReportsStatus: false
            }

        case GET_PRINT_REPORT_EMPLOYEE_LIST:
            return {
                ...state,
                employeePrintList: payload?.data?.result || [],
                employeePrintListStatus: payload?.status || false,
                employeePrintListMsg: payload?.msg || 'Something Went Wrong',
                employeePrintListTotalItems: payload?.data?.totalItems || 0,
                employeePrintListCurrentPage: payload?.data?.currentPage || 0,
            }
        case GET_REPORT_LIST:
            return {
                ...state,
                reportsList: payload?.data || [],
                // employeeReportsStatus: false
            }

        case GET_PRINT_FEES_STU_REPORT_LIST:
            return {
                ...state,
                studentFeesReportList: payload?.data || [],
                currentYear: payload?.currentYear || {},
                nextYear: payload?.nextYear || {}
            }
        case GET_PRINT_FEES_STUDENT_LIST:
            return {
                ...state, feesReportStudentList: payload?.data?.result || [],
                feesReportStudentListStatus: payload?.status || false,
                feesReportStudentListMsg: payload?.msg || 'Something Went Wrong',
                feesReportStudentListTotalItems: payload?.data?.totalItems || 0,
                feesReportStudentListCurrentPage: payload?.data?.currentPage || 0,
            }

        default:
            return state;
    }
    // return state;
}

export default DynamicReportArea;